.project3{
  width: 100%;
  height: 100%;
  margin-top: 80px;
  margin-bottom: 0px;

.description3{
  height:650px;
  padding-top: 40px;
  color: black;
  h2 {
    font-family: 'Unbounded';
    font-weight: 600;
    font-size: 70px;
    cursor: pointer;
    color: black;
  }
  p{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 100;
     margin-top: 30px;
  }
  .descrip3 {
    font-family: 'Roboto', sans-serif ;
    padding-right: 90px ;
    font-size: 24px;
    margin-top: 55px;
    letter-spacing: 2.5px;
  }
  h5 {
    margin-top: 40px;
    font-family: 'Times New Roman', sans-serif;
    font-size: 20px;
    cursor: pointer;
  }
}
.projectImage3 {
  min-width: 50%;
  height:650px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  overflow: hidden;
} 
.HomeVideo3 {
  width: 100%;
  box-shadow:0px 0px 0px 12px black inset;
  margin-bottom:20px;
  border: 5px solid black;
  border-radius: 12px;
  box-shadow: 3px 5px 8px lightslategray;
  cursor: pointer;
}
}




.project4{
  width: 100%;
  height: 100%;
  margin-top: 80px;
  margin-bottom: 0px;

.description4{
  height:650px;
  padding-top: 40px;
  color: black;
  h2 {
    font-family: 'Unbounded';
    font-weight: 600;
    font-size: 70px;
    cursor: pointer;
    color: black;
  }
  p{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 100;
     margin-top: 30px;
  }
  .descrip4 {
    font-family: 'Roboto', sans-serif ;
    padding-right: 90px ;
    font-size: 24px;
    margin-top: 55px;
    letter-spacing: 2.5px;
  }
  h5 {
    margin-top: 40px;
    font-family: 'Times New Roman', sans-serif;
    font-size: 20px;
    cursor: pointer;
  }
}
.projectImage4 {
  min-width: 50%;
  height:850px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;  
} 
.HomeVideo4 {
  width: 65%;
  box-shadow:0px 0px 0px 12px black inset;
  margin-bottom:20px;
  border: 5px solid black;
  border-radius: 12px;
  box-shadow: 3px 5px 8px lightslategray;
  cursor: pointer;
}
}



.project5{
  width: 100%;
  height: 100%;
  margin-top: 80px;
  margin-bottom: 0px;

.description5{
  height:650px;
  padding-top: 40px;
  color: black;
  h2 {
    font-family: 'Unbounded';
    font-weight: 600;
    font-size: 70px;
    cursor: pointer;
    color: black;
  }
  p{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 100;
     margin-top: 30px;
  }
  .descrip5 {
    font-family: 'Roboto', sans-serif ;
    padding-right: 90px ;
    font-size: 24px;
    margin-top: 55px;
    letter-spacing: 2.5px;
  }
  h5 {
    margin-top: 40px;
    font-family: 'Times New Roman', sans-serif;
    font-size: 20px;
    cursor: pointer;
  }
}
.projectImage5 {
  min-width: 50%;
  height:650px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  overflow: hidden;
} 
.HomeVideo5 {
  width: 100%;
  box-shadow:0px 0px 0px 12px black inset;
  margin-bottom:20px;
  border: 5px solid black;
  border-radius: 12px;
  box-shadow: 3px 5px 8px lightslategray;
  cursor: pointer;
}
}



.project6{
  width: 100%;
  height: 100%;
  margin-top: 80px;
  margin-bottom: 0px;

.description6{
  height:650px;
  padding-top: 40px;
  color: black;
  h2 {
    font-family: 'Unbounded';
    font-weight: 600;
    font-size: 70px;
    cursor: pointer;
    color: black;
  }
  p{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 100;
     margin-top: 30px;
  }
  .descrip6 {
    font-family: 'Roboto', sans-serif ;
    padding-right: 90px ;
    font-size: 24px;
    margin-top: 55px;
    letter-spacing: 2.5px;
  }
  h5 {
    margin-top: 40px;
    font-family: 'Times New Roman', sans-serif;
    font-size: 20px;
    cursor: pointer;
  }
}
.projectImage6 {
  min-width: 50%;
  height:700px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
} 
.HomeVideo6 {
  margin-bottom:20px;
  border: none;
  border-radius: 12px;
  box-shadow: 0px;
  cursor: pointer;
}
}