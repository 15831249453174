.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0px;
  }
  
  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .app__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
  
    img {
      width: 100%;
      height: 170px;
      border-radius: 15px;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;
  
      img {
        height: 320px;
      }
    }
  }
  .aboutme {
    width: 57%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    h3{
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
    }
    h3:hover {
      cursor: pointer;
    }
  }
  .paragraph{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content:center;
    font-family: 'Centra', sans-serif !important;
    font-weight: 400;
    font-size: 17px;
    line-height: 22pt;
    letter-spacing: 0.3pt;
    color: black;
    span{
      color: rgb(1, 1, 160);
    }
  }
  .stockPhoto {
    width: 100%;
    // height: 400px;
    display: flex;
    justify-content: center;
  }
  .developer {
    height: 100%;
    width: 100%;
    max-width: 300px;
  }
  @media screen and (max-width: 1850px) {
    .aboutme {
      width: 67%;
      margin-bottom: 20px;
    }

    .paragraph{
      font-size: 17px;
    }

  }
  
  @media screen and (max-width: 1650px) {
    .aboutme {
      width: 70%;
      margin-bottom: 20px;
    }

    .paragraph{
      font-size: 14px;
    }

  }
  @media screen and (max-width: 1450px) {
    .aboutme {
      width: 90%;
      margin-bottom: 20px;
    }

    .paragraph{
      font-size: 14px;
    }

  }
  @media screen and (max-width: 1200px) {
    .aboutme {
      width: 80%;
      margin-bottom: 10px;
    }

    .paragraph{
      font-size: 14px;
    }
    .developer {
      height: 80%;
      width: 80%;
    }
  }
  @media screen and (max-width: 815px) {
    .aboutme {
      width: 90%;
      margin-bottom: 10px;
    }

    .paragraph{
      font-size: 14px;
      line-height: 16pt;
      letter-spacing: 0.3pt;
    }
    .developer {
      height: 80%;
      width: 80%;
    }
  }
  @media screen and (max-width: 715px) {
    .aboutme {
      width: 92%;
      margin-bottom: 12px;
    }

    .paragraph{
      font-size: 12px;
      line-height: 16pt;
      letter-spacing: 0.3pt;
    }
    .developer {
      height: 80%;
      width: 80%;
    }
  }
  @media screen and (max-width: 455px) {
    .aboutme {
      width: 94%;
      margin-bottom: 14px;
      
    }

    .paragraph{
      font-size: 11px;
      line-height: 16pt;
      letter-spacing: 0.3pt;
    }
    .developer {
      height: 70%;
      width: 70%;
    }
  }
  @media screen and (max-width: 405px) {
    .aboutme {
      width: 95%;
      margin-bottom: 12px;
    }

    .paragraph{
      font-size: 10px;
      line-height: 16pt;
      letter-spacing: 0.3pt;
    }
    .developer {
      height: 70%;
      width: 70%;
    }
  }