.contactSection{
    display: flex;
    justify-content: center;
    height: 80%;
    width: 100%;
    padding-bottom: 20px;
    position: absolute;
    z-index: 2;
  }
  .Email {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .MainForm {
    height: 100%;
    width: 80%;
    margin-top: 0px;
  }
  .from {
    margin-top: 15px;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
  }
  .to{
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
  }
  .message {
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
  }
  .yourEmail {
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
  }
  .getintouch{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    margin-bottom: 0px;

  }
  .getintouch h1{
    color: black;
    font-family: NerkoOne;
    font-size: 65px;
  }
  .WaysToContact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .Number {
    background-color: rgb(248, 250, 246);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem;
    border-radius: 4%;
    box-shadow: 3px 3px 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      transform: translateY(-5px);
    }
    a{
      text-decoration: none;
      .emailPic {
        color: black;
      }
        color: black;
    }
  }
  .EmailAddress {
    background-color: rgb(248, 250, 246);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem;
    border-radius: 4%;
    box-shadow: 3px 3px 5px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    a{
      text-decoration: none;
      .emailPic {
        color: black;
      }
      span {
        margin-left: 0.3rem;
        color: black;
      }
      &:hover span{
        color: blue;
      }
      &:hover .emailPic {
        color: blue;
      }
      

    }
    &:hover {
      transform: translateY(-5px);
    }
  }
  .status{
    font-style: Arial;
    font-size: medium;
  }
  @media screen  and (max-width:1300px){
    .getintouch h1{
      font-size: 65px;
    }
    .Number {
      font-size: small;
    }
    .EmailAddress {
      font-size: small;
    }
  }
  @media screen  and (max-width:1200px){
    .Number {
      font-size: medium;
    }
    .EmailAddress {
      font-size: medium;
    }
  }
  @media screen  and (max-width:700px){
    .getintouch h1{
      font-size: 65px;
    }
    .Number {
      font-size: small;
    }
    .EmailAddress {
      font-size: small;
    }
  }
  @media screen  and (max-width:580px){
    .getintouch h1{
      font-size: 50px;
    }
    .Number {
      font-size:smaller;
      margin: 0;
      margin-bottom: 1rem;
    }
    .EmailAddress {
      font-size: smaller;
      margin: 0;
    }
  }
.copyright2 {
    color: black;
    z-index: 3;
    position: sticky;
    text-align: center;
    font-weight: bold;
}