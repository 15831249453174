.ProjectContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
}
.container {
    width: 100%;
    height: 100vh;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ProjectNav {
    background-color: black;
    position: absolute;
    height: 50px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        all:unset;
        cursor: pointer;
        color: white;
        font-weight: bold;
        margin-left: 2rem;
    }
    h4 {
        color: white;
        cursor: pointer;
    }
    .dropdown {
        all:unset;
        cursor: pointer;
    }
    #dropdown-basic{
        all:unset;
        color: white;
        font-weight: bold;
        margin-right: 2rem;
    }
}
.project01 {
    width: 100%;
    height: 100%;
}
.projectDescription {
    padding-top:100px ;
    height: 100%;
    h2{
        font-family: 'Unbounded', sans-serif !important;
        font-weight: bold;
        font-size: 70px;
    }
    h5{
        font-weight: bold;
        margin-top: 30px;
    }
    p{
        margin-top: 10px;
    }
}
.projectVideo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;  
}
.NewsfeedVid {
    margin-top: 50px;
    height: 800px;
    box-shadow:0px 0px 0px 12px black inset;
    margin-bottom:20px;
    border: 5px solid black;
    border-radius: 12px;
    box-shadow: 3px 5px 8px lightslategray;
    cursor: pointer;
}
@media screen and (min-height: 1200px) { 
    .projectVideo {
        .NewsfeedVid {
        height: 1050px;
        }
    }
}
@media screen and (max-height: 970px) { 
    .projectDescription {
         padding-top:60px ;
    }
}
@media screen and (max-height: 900px) { 
    .projectVideo {
         padding-top:60px ;
    }
}
@media screen and (max-height: 800px) { 
    .NewsfeedVid {
        margin-top: 50px;
        height: 650px;
    }
}
@media screen and (max-height: 800px) { 
    .NewsfeedVid {
        margin-top: 120px;
        height: 650px;
    }
}
@media screen and (max-width: 600px) { 
    .ProjectNav {
        h4 {
            display: none;
        }
        .dropdown {
            display: none;
        }
    }
    .projectDescription {
        height: 100%;
        h2{
            font-family: 'Unbounded', sans-serif !important;
            font-weight: bold;
            font-size: 50px;
        }
        h5{
            font-weight: bold;
            margin-top: 25px;
        }
        p{
            margin-top: 8px;
        }
    }
    .projectVideo {
        .NewsfeedVid{
            margin-top: 0px;
            height: 600px;
        }
     
    }
}
@media screen and (max-width: 300px) { 
    .projectVideo {
        .NewsfeedVid{
            height: 500px;
        }
     
    }
}