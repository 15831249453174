.app__navbar {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0rem 0rem;
    margin-top: 0px;
    padding-bottom:0px ;
    margin-bottom: 0px;
    // background: rgba(255, 255, 255, 0.25);
    // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    // border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 4;
    padding-top: 5px;
  }
  

  .LogoButton {
      border: none;
      background: none;
      cursor: pointer;
      margin: 0;
      z-index: 3;
      display: flex;
      justify-content:flex-end;
      align-items: auto;
      margin: 5px;
      height: 100%;
      cursor: pointer;

    .LogoPic {
      height:100%;
      display: flex;
      cursor: pointer;
      margin-left: 5px;
      padding: 5px;
      @media screen and (min-width: 2000px) {
        width: 170px;
        height: 36px;
      }
    }
    }
  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: right;
    align-content: center;
    list-style: none;
    padding-right: 20px;
    z-index: 9;
    li {
      margin: 0 1rem;
      cursor: pointer;
      color: var(--secondary-color);
      flex-direction: column;
      font-weight: bolder;
      
  
      div {
        width: 50px;
        height: 2px;
        background: transparent;
        border-radius: 50%;
        margin-bottom: 5px;
        
      }
  
      a {
        color: var(--gray-color);
        text-decoration: none;
        flex-direction: column;
        
        text-transform: uppercase;
        font-weight: 600;
  
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: var(--secondary-color);

        }
        &.active {
          color: rgb(87, 85, 85);
          font-weight: bolder;
        }
    }
  
      &:hover {
        div {
          background: var(--secondary-color);
        }
      }
    }
  
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .blogs {
    color: var(--gray-color) !important;
  }
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    margin: 5px;
    svg {
      width: 70%;
      height: 70%;
      color: var(--white-color);
    }
  
    div {
      position: fixed;
      z-index: 5;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 9;
  
      padding: 1rem;
  
      width: 50%;
      height: 100vh;
      
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      
      background-color: var(--white-color);
      background-size: cover;
      background-repeat: repeat;
  
      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--secondary-color);
            }
            &.active {
              color: var(--secondary-color);
              font-weight: bolder;
            }
          }
        }
      }
  
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  
    @media screen and (min-width: 900px) {
      display: none;
    }
  }