.home {
  width: 100vw;
  height: 100vh;
  background-color: #111b2c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
  .Media {
    position: absolute;
    left: 0;
    bottom:0;
  }
  p {
    color: rgb(255, 0, 43);
    font-weight:500;
    letter-spacing: 1.4px;

  }
  h1 {
    color: white;
    font-family: 'Unbounded';
    cursor: pointer;
    font-size: 70px;


  }
  h2 {
    color: rgb(107, 107, 107);
    font-family: 'Roboto';
    font-size: 70px;

  }
  .Description{
    margin-top: 20px;
    color: rgb(185, 184, 184);
    font-family: 'Roboto', sans-serif ;
    letter-spacing: 1.2px;
    line-height: 150%;
;
    // width: 50%;
  }
  .projectLink{
    margin-top: 20px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .home {
    p {
      color: rgb(255, 0, 43);
      font-weight:500;
      letter-spacing: 1.4px;
    }
    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 50px;
    }
    .Description{
      margin-top: 20px;
      letter-spacing: 1.2px;
      line-height: 150%;
      font-size: 15px
    }
    .projectLink{
      margin-top: 20px;
    }
    .Media {
      display: none;
    }
  }
}
@media screen and (max-width: 800px) {
  .home {
    p {
      color: rgb(255, 0, 43);
      font-weight:500;
      letter-spacing: 1.4px;
    }
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 40px;
    }
    .Description{
      margin-top: 20px;
      letter-spacing: 1.0px;
      line-height: 150%;
      font-size: 13px
    }
    .projectLink{
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .home {
    p {
      color: rgb(255, 0, 43);
      font-weight:500;
      letter-spacing: 1.4px;
    }
    h1 {
      font-size: 37px;
    }
    h2 {
      font-size: 37px;
    }
    .Description{
      margin-top: 20px;
      letter-spacing: 1.0px;
      line-height: 150%;
      font-size: 12px
    }
    .projectLink{
      margin-top: 20px;
    }
  }
}