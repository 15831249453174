.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }

  .app__skills-container {
    width: 100%;
    display: flex;
    flex-direction: row;
  
    @media screen and (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }
  }
  
  .app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  
    margin-right: 5rem;
  
    @media screen and (max-width: 900px) {
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
  }
  
  .app__skills-item {
    flex-direction: column;
    text-align: center;
  
    // margin: 1rem;
  
    transition: all 0.3s ease-in-out;
  
    div {
      width: 135px;
      height: 135px;
      margin: 15px;
      border-radius: 10%;
      background-color: #ffffff;
      box-shadow: 10px 10px 5px rgb(18, 90, 114);
      overflow:visible;

      img {
        width: 68%;
        height: 68%;
        border-radius: 20px;
        transition: .3s ease-in-out;
      }
  
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
        filter: grayscale(0%);
        cursor: pointer;
      }

      &:hover img{
        width: 115%;
        height: 115%;
      }
  
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
    
      @media screen and (max-width: 850px) {
        width: 90px;
        height: 90px;
      }
      @media screen and (max-width: 450px) {
        width: 70px;
        height: 70px;
      }
      @media screen and (max-width: 1100px) {
        .skillDiv {
            width: 105px;
            height: 105px;
          }
          img {
            width: 58%;
            height: 58%;
            border-radius: 20px;
            transition: .3s ease-in-out;
          }
          &:hover img{
            width: 105%;
            height: 105%;
          }
      }
    }
  
    p {
      font-weight: 500;
      margin-top: 0.5rem;
    }
  
    @media screen and (min-width: 2000px) {
      margin: 1rem 2rem;
  
      p {
        margin-top: 1rem;
      }
    }
  }
  .SkillsH2 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 40px;
    font-weight: 700;
    cursor: pointer;
    color: black;
  }
   .skillsSection {
    padding-top: 0px;
    margin-top: 0px;
    .col-xl-9{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0px;
    }
   }
  .app__work-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    margin-top: 20px;
  }
  .app__work-filter-item{
      box-shadow: 5px 5px;
  }
  .app_work-filter-item:active{
    box-shadow: 5px 5px;
  }
  
